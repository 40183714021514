<template>
	<div>
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <div class="d-flex flex-row align-center pt-3 mr-5">
                    <v-select
                        :items="searchFields"
                        :placeholder="$t('message.searchColumn')"
                        autocomplete="new-password"
                        class="filter-width-175 force-text-left"
                        clearable
                        dense
                        hide-details="auto"
                        solo
                        v-model="searchField"
                    />
                    <v-text-field
                        :placeholder="$t('message.searchTerm')"
                        autocomplete="new-password"
                        class="filter-width-200 force-text-left ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        append-icon="search"
                        solo
                        v-model="searchTerm"
                        @keyup.enter="searchActiveSuspendedBuyers()"
                    />
                    <v-btn
                        :loading="loading.search"
                        class="ml-2 px-2"
                        @click="searchActiveSuspendedBuyers()"
                    >{{ $t('message.search') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.reset"
                        class="ml-2 px-2"
                        @click="resetSearch()"
                    >{{ $t('message.reset') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.suspend"
                        :disabled="selectedBuyers.length == 0"
                        class="ml-2 px-2 error"
                        @click="suspendMultipleBuyers()"
                        v-if="$can('update','Buyer')"
                    >{{ $t('message.suspendBuyers') }}</v-btn>
                </div>
                <v-spacer/>
                <ExportTableJson
                    :export-conditions="exportConditions"
                    :export-data="filteredActiveSuspendedBuyers"
                    :export-fields="headers"
                    :export-source="'customers'"
                    class="ml-3"
                />
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.buyers"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    />
                </v-col>
            </v-row>
        </v-overlay>
        <v-overlay
            :value="loading.viewProfile"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    />
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: itemsPerPageOptions,
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :hide-default-header="hideDefaultHeader"
            :items="filteredActiveSuspendedBuyers"
            :options.sync="tableOptions"
            :show-select="$can('update','Buyer')"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            id="buyerTable"
            item-key="Customer.id"
            v-model="selectedBuyers"
        >
            <template v-slot:item.Customer.title="{ item }">
                <span class="text-no-wrap font-weight-bold">{{ item.Customer.title }}</span>
            </template>
            <template v-slot:item.Customer.otsname="{ item }">
                <span :class="'text-no-wrap' + (item.Customer.otsname == '' ? ' red--text' : '')">{{ item.Customer.otsname ? item.Customer.otsname : item.Customer.title  }}</span>
            </template>
            <template v-slot:item.Customer.removed="{ item }">
                <span class="green--text darken-1" v-if="item.Customer.removed == 0">{{ $t('message.activated') }}</span>
                <span class="red--text darken-1" v-else>{{ $t('message.suspended') }}</span>
            </template>
            <template v-slot:item.Company.address1="{ item }">
                <span>{{ [item.Company.address1,item.Company.address2,item.Company.address3].join(' ') }}</span>
            </template>
            <template v-slot:item.Company.city="{ item }">
                <span :class="'text-no-wrap' + (item.Company.city == '' ? ' red--text' : '')">{{ item.Company.city ? item.Company.city : $t('message.valueMissing') }}</span>
            </template>
            <template v-slot:item.Company.state="{ item }">
                <span :class="'text-no-wrap' + (item.Company.state == '' ? ' red--text' : '')">{{ item.Company.state ? item.Company.state : $t('message.valueMissing') }}</span>
            </template>
            <template v-slot:item.Customer.id="{ item }">
                <v-menu>
                    <template v-slot:activator="{ on: menu }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon v-on="{ ...tooltip, ...menu}">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.moreActions') }}</span>
                        </v-tooltip>
                    </template>
                    <v-list dense>
<!--                        <v-list-item class="font-sm" @click="viewBuyerProfile(item.Customer.id)" v-if="$can('read','Buyer')">-->
<!--                            <v-icon color="green darken-1" small class="mr-1 mdi mdi-eye"></v-icon>-->
<!--                            {{ $t('message.viewBuyer') }}-->
<!--                        </v-list-item>-->
                        <v-list-item class="font-sm" @click="viewBuyerProfile(item.Customer.id)" v-if="$can('read','Buyer')">
                            <v-progress-circular :size="20" :width="2" indeterminate color="green darken-1" class="mr-2" v-if="loading.url"/>
                            <v-icon color="green darken-1" small class="mr-1 mdi mdi-eye" v-else></v-icon>
                            <span>{{ $t('message.viewBuyer') }}</span>
                        </v-list-item>
                        <v-list-item class="font-sm" @click="updateBuyer(item.Customer.id)" v-if="$can('update','Buyer')">
                            <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>
                            {{ $t('message.updateBuyer') }}
                        </v-list-item>
                        <v-list-item class="font-sm" @click="suspendBuyer(item.Customer.id)" v-if="item.Customer.removed == 0 && $can('update','Buyer')">
                            <v-icon small class="mr-1" color="red darken-1">pause</v-icon>
                            {{ $t('message.suspendBuyer') }}
                        </v-list-item>
                        <v-list-item class="font-sm" @click="activateBuyer(item.Customer.id)" v-if="item.Customer.removed != 0 && item.Customer.removed != null && $can('update','Buyer')">
                            <v-icon small class="mr-1" color="green darken-1">mdi-play</v-icon>
                            {{ $t('message.activateBuyer') }}
                        </v-list-item>
                        <v-list-item class="font-sm" @click="openDeleteBuyerDialog(item.Customer.id, item.Customer.otsname)" v-if="$can('destroy','Buyer')">
                            <v-icon color="red lighten-1" small class="mr-1">delete</v-icon>
                            {{ $t('message.deleteBuyer') }}
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
        <SimpleAlert
            :alert_message.sync="dialogs.error_message"
            :dialog.sync="dialogs.error"
            @dialog-closed="dialogClosed"
        />
        <DeleteBuyer
            :dialog.sync="deleteBuyerDialog"
            :buyer-id="selectedBuyerId"
            :buyer-name="selectedBuyerName"
            :page-key="pageKey"
            @dialog-closed="deleteBuyerDialogClosed"
            @delete-done="buyerDeleted"
        />
<!--        <BuyerProfileView-->
<!--            :buyer-id="selectedBuyerIdToView"-->
<!--            @pdf-printed="profilePrinted()"-->
<!--            @pdf-not-printed="profileNotPrinted()"-->
<!--            v-if="viewProfile"-->
<!--        />-->
	</div>
</template>
<script>
	import { mapGetters, mapActions } from "vuex";
    import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    // import SimpleAlert from "./SimpleAlert";
    import {formatDate} from "Helpers/helpers";
    import {api} from "Api";

    const ExportTableJson = () => import("Components/Appic/ExportTableJson");
    const SimpleAlert = () => import("./SimpleAlert");
    const DeleteBuyer = () => import("Components/Appic/DeleteBuyer");
    // const BuyerProfileView = () => import("Components/Appic/BuyerProfileView");

	export default {
		name: "BuyersListing",
		components: {
            // BuyerProfileView,
            DeleteBuyer,
            ExportTableJson,
            SimpleAlert
        },
		data() {
			return {
				loader: false,
				deleteBuyerDialog: false,
                dialogs: {
					error: false,
					error_message: ""
				},
                exportConditions: {},
				filterOptions: {},
                hideDefaultHeader: false,
                hideDefaultFooter: false,
				loading: {
                    bulkSuspend: false,
                    buyers: false,
                    filter: {
						countries: false,
						salescolleagues: false,
						productsegments: false,
						statuses: false
					},
                    search: false,
                    url: false,
                    viewProfile: false
				},
				searchField: 'Customer.otsname', //default search field
				searchTerm: null,
                selectedBuyerId: null,
                selectedBuyerIdToView: null,
                selectedBuyerName: null,
                selectedBuyers: [],
				tableOptions: {},
                viewProfile: false
			}
		},
		computed: {
			...mapMultiRowFields('buyer', {
				allBuyers: 'allBuyers',
                allActiveSuspendedBuyers: 'allActiveSuspendedBuyers',
				filteredBuyers: 'filteredBuyers',
                filteredActiveSuspendedBuyers: 'filteredActiveSuspendedBuyers'
			}),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
			...mapGetters('buyer', {
				buyerCountries: 'buyerCountries',
				buyerSalesColleagues: 'buyerSalesColleagues',
				buyerProductSegments: 'buyerProductSegments'
			}),
            ...mapGetters('user',{
                User: 'User'
            }),
            //TODO this is temporary remove later when casl is applied
            canSuspendActivate () {
                return ['vanjoe@apptimber.com'].includes(this.User.email)
            },
			console: () => console,
			formatDate: () => formatDate,
			headers() {
				let headers = [
                    {
                        id: 1,
                        text: this.$t('message.actions'),
                        value: 'Customer.id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 2,
                        text: this.$t('message.status'),
                        value: "Customer.removed",
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
					{
                        id: 3,
						text: this.$t('message.otsName'),
						value: "Customer.otsname",
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: true,
                        sortable: false
					},
					{
                        id: 4,
						text: this.$t('message.address'),
						value: "Company.address1",
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: false,
                        sortable: false
					},
					{
                        id: 5,
						text: this.$t('message.city'),
						value: "Company.city",
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: true,
                        sortable: false
					},
					{
                        id: 6,
						text: this.$t('message.state'),
						value: "Company.state",
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: false,
                        sortable: false
					},
					{
                        id: 7,
						text: this.$t('message.country'),
						value: 'Country.name',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: true,
                        sortable: false
					},
					{
                        id: 8,
						text: this.$t('message.salesColleague'),
						value: "Salescontact.name",
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: true,
                        sortable: false
					},
					{
                        id: 9,
						text: this.$t('message.productSegment'),
						value: "Marketsegment.title",
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: true,
                        sortable: false
					},
                    {
                        id: 10,
                        text: this.$t('message.customerNumber'),
                        value: 'Company.GPCustomerNumber',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false
                    }
				]
				return headers
			},
            itemsPerPageOptions() {
                let options = [10,20,50,-1];
                if(window.screen.height >= 800){
                    options = [15,30,50,-1];
                }
                return options;
            },
            pageKey() {
                return Math.floor(Math.random() * 100)
            },
			searchFields() {
				return this.headers.filter((header) => header.searchable === true)
			}
		},
		methods: {
			...mapActions('buyer', {
                activateBuyerById: 'activateBuyerById',
                filterAllActiveSuspendedBuyers: 'filterAllActiveSuspendedBuyers',
				filterAllBuyers: 'filterAllBuyers',
				getAllActiveBuyers: 'getAllActiveBuyers',
				getAllBuyers: 'getAllBuyers',
                getAllBuyerCountries: 'getAllBuyerCountries',
				getAllBuyerSalesColleagues: 'getAllBuyerSalesColleagues',
				getAllBuyerProductSegments: 'getAllBuyerProductSegments',
				resetAllBuyers: 'resetAllBuyers',
				searchAllBuyers: 'searchAllBuyers',
                searchAllActiveSuspendedBuyers: 'searchAllActiveSuspendedBuyers',
				suspendBuyerById: 'suspendBuyerById',
                suspendMultipleBuyersById: 'suspendMultipleBuyersById'
			}),
            activateBuyer(val) {
                this.activateBuyerById(val).then((status) => {
                    if (status == 'done') {
                        this.$toast.success(this.$t('message.successes.buyerActivated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.resetAllBuyers()
                            .then(() => {
                                this.getAllBuyers()
                                    .then((response) => {
                                        if(response == 'done'){
                                            this.searchActiveSuspendedBuyers()
                                        }
                                    })
                            })
                            .catch(() => {

                            })
                    } else {
                        this.$toast.error(this.$t('message.errors.buyerNotActivated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                })
            },
            buyerDeleted() {
                this.resetAllBuyers()
                if (this.allBuyers.length == 0) this.getAllActiveBuyers()
                if (this.allActiveSuspendedBuyers.length == 0) this.getAllBuyers()
            },
            deleteBuyerDialogClosed() {
                this.deleteBuyerDialog = false;
            },
			dialogClosed() {
				this.dialogs.error = false;
				this.dialogs.error_message = ''
			},
			filterBuyers() {
				this.loading.buyers = true;
				let payload = {
					filterOptions: this.filterOptions
				}
				this.filterAllBuyers(payload)
					.then(() => {
						this.loading.buyers = false;
					})
				let _this = this
				setTimeout(function () {
					_this.loading.buyers = false
				}, 1000);
			},
            filterActiveSuspendedBuyers() {
                this.loading.buyers = true;
                let payload = {
                    filterOptions: this.filterOptions
                }
                this.filterAllActiveSuspendedBuyers(payload)
                    .then(() => {
                        this.loading.buyers = false;
                    })

                let _this = this
                setTimeout(function () {
                    _this.loading.buyers = false
                }, 1000);
            },
            newBuyer() {
                let tab = window.open('/v1/customers/add', '_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
                // router.push({name: 'add_customer'}).catch(err => {})
            },
            profileNotPrinted() {
                this.selectedBuyerIdToView = null
                this.viewProfile = false
                this.loading.viewProfile = false
            },
            profilePrinted() {
			    this.selectedBuyerIdToView = null
                this.viewProfile = false
                this.loading.viewProfile = false
            },
            openDeleteBuyerDialog( buyerId, buyerName ) {
			    this.selectedBuyerId = buyerId
                this.selectedBuyerName = buyerName
                this.deleteBuyerDialog = true;
            },
			resetSearch() {
				this.searchField = 'Customer.title'; //default search field
				this.searchTerm = null;
				this.filterOptions = {};
			},
			searchBuyers() {
				if (this.searchField == null || this.searchTerm == null) {
					this.dialogs.error = true
					this.dialogs.error_message = this.$t('message.errors.noSearchColumnAndSearchTerm')
				} else {
					this.loading.search = true
                    this.tableOptions.page = 1
					let payload = {
						search: {
							field: this.searchField,
							value: this.searchTerm
						}
					}
					this.searchAllBuyers(payload)
						.then(() => {
							this.loading.search = false;
						})
				}
			},
            searchActiveSuspendedBuyers() {
                if (this.searchField == null || this.searchTerm == null) {
                    this.dialogs.error = true
                    this.dialogs.error_message = this.$t('message.errors.noSearchColumnAndSearchTerm')
                } else {
                    this.loading.search = true;
                    this.tableOptions.page = 1
                    let payload = {
                        search: {
                            field: this.searchField,
                            value: this.searchTerm
                        }
                    }
                    this.searchAllActiveSuspendedBuyers(payload)
                        .then(() => {
                            this.loading.search = false;
                        })
                }
            },
			async suspendBuyer(val) {
				if (await this.$root.$confirm(this.$t('message.suspendBuyer'), this.$t('message.confirmations.continueBuyerSuspendAction'), {color: 'orange'})) {
					this.suspendBuyerById(val)
                        .then((status) => {
                            if (status == 'done') {
                                this.$toast.success(this.$t('message.successes.buyerSuspended'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.resetAllBuyers()
                                    .then(() => {
                                        this.getAllBuyers()
                                            .then((response) => {
                                                if(response == 'done'){
                                                    this.searchActiveSuspendedBuyers()
                                                }
                                            })
                                    })
                                    .catch(() => {

                                    })
                            } else {
                                this.$toast.error(this.$t('message.errors.buyerNotSuspended'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
					    })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.buyerNotSuspended'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
				}
			},
            async suspendMultipleBuyers() {
                if (await this.$root.$confirm(this.$t('message.suspendBuyers'), this.$t('message.confirmations.continueBuyersSuspendAction'), {color: 'orange'})) {

                    let selectedBuyerIds = []
                    this.selectedBuyers.forEach(buyer => {
                        selectedBuyerIds.push(buyer.Customer.id)
                    })

                    this.suspendMultipleBuyersById(selectedBuyerIds)
                        .then((status) => {
                            if (status == 'done') {
                                this.$toast.success(this.$t('message.successes.buyersSuspended'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.selectedBuyers = []
                                this.resetAllBuyers()
                                    .then(() => {
                                        this.getAllBuyers()
                                            .then((response) => {
                                                if(response == 'done'){
                                                    if(this.searchField != null && this.searchTerm != null) {
                                                        this.searchActiveSuspendedBuyers()
                                                    }
                                                }
                                            })
                                    })
                                    .catch(() => {

                                    })
                            } else {
                                this.$toast.error(this.$t('message.errors.buyersNotSuspended'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.buyerNotSuspended'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                }
            },
			updateBuyer(val) {
                let tab = window.open('/v1/customers/update/' + val, '_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
				// router.push({name: 'update_customer', params: { buyerId : val}}).catch(err => {})
			},
            viewBuyerProfile(val) {
                const promise = new Promise((resolve, reject) => {
                    api
                        .get("/print-url/buyer-profile/" + val)
                        .then(response => {
                            resolve(response)
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
                this.loading.url = true
                promise
                    .then(response => {
                        if(response.data.status == 'success') {
                            let tab = window.open(response.data.url, '_blank');
                            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                            this.openedTabs.push(tab.name)
                        } else {
                            this.$toast.error(this.$t('message.errors.pdfError'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                })
                        }
                        this.loading.url = false
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.pdfError'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            })
                        this.loading.url = false
                    })
            }
            // viewBuyerProfile(val) {
			//     this.selectedBuyerIdToView = val
            //     this.loading.viewProfile = true
            //     this.viewProfile = true
            // }
		},
		watch: {
			filterOptions: {
				handler() {
					if (!this.loading.search) {
						// this.filterBuyers()
                        this.filterActiveSuspendedBuyers()
					}
				},
				deep: true
			}
		},
		created() {
			//reset
            this.loading.buyers = true
			this.resetAllBuyers()
			if (this.allBuyers.length == 0) this.getAllActiveBuyers()
            if (this.allActiveSuspendedBuyers.length == 0) {
                this.getAllBuyers()
                    .then(() => {
                        this.loading.buyers = false
                    })
                    .catch(() => {
                        this.loading.buyers = false
                    })
            }

			//get all filter options
			this.loading.filter.countries = true;
			this.loading.filter.salescolleagues = true;
			this.loading.filter.productsegments = true;
			// window.addEventListener('resize', this.handleResize)
			this.getAllBuyerCountries()
				.then(() => {
					this.loading.filter.countries = false
					return 'ok'
				})
				.then(() => {
                    this.getAllBuyerSalesColleagues()
                })
				.then(() => {
					this.loading.filter.salescolleagues = false
					return 'ok'
				})
				.then(() => {
                    this.getAllBuyerProductSegments()
                })
				.then(() => {
					this.loading.filter.productsegments = false
					return 'ok'
				})
		}
	}
</script>

<style>
.filter-width-175 {
    width: 175px !important;
    max-width: 175px !important;
}
.filter-width-200 {
    width: 200px !important;
    max-width: 200px !important;
}
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}

.v-data-table th:first-child {
    background-color: #c5e1a5 !important;
    border-color: #c5e1a5 !important;
}

.v-data-table td:first-child {
    padding-left: 3px !important;
}

.v-data-table tbody td {
    vertical-align: middle !important;
}
</style>
